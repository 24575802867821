import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

if (
  process.env.NEXT_PUBLIC_ENV &&
  (process.env.NEXT_PUBLIC_ENV === "preview" ||
    process.env.NEXT_PUBLIC_ENV === "local")
) {
  const firebaseConfig = {
    apiKey: "AIzaSyBWcyZlYSk9x_OSHnJ_finO7moQiOy98Xg",
    authDomain: "nucalmv3-dev.firebaseapp.com",
    databaseURL: "https://nucalmv3-dev.firebaseio.com",
    projectId: "nucalmv3-dev",
    storageBucket: "nucalmv3-dev.appspot.com",
    messagingSenderId: "500876148224",
    appId: "1:500876148224:web:3bdd00e7c8f5d0db0ba2e5",
    measurementId: "G-JVRBQ7VKE1",
  };
  // Initialize Firebase
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  } else {
    firebase.app(); // if already initialized, use that one
  }
} else {
  const config = {
    apiKey: "AIzaSyBgTar7KCG0XkXKe7MIsQk3Xlr2Zz0PGIc",
    authDomain: "nucalmv3.firebaseapp.com",
    databaseURL: "https://nucalmv3.firebaseio.com",
    projectId: "nucalmv3",
    storageBucket: "nucalmv3.appspot.com",
    messagingSenderId: "213610832352",
    appId: "1:213610832352:web:9ffbc425d1c51854138b85",
    measurementId: "G-0TGWV486E0",
  };
  // Initialize Firebase
  if (!firebase.apps.length) {
    firebase.initializeApp(config);
  } else {
    firebase.app(); // if already initialized, use that one
  }
}

export const db = firebase.firestore();

export default firebase;
